import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Profile from "../components/Profile";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`いさりび会`}</h2>
    <Profile name="いさりび" nickname="???" icon="/images/isaribikai/isaribi.jpg" weapons={["デュアルスイーパーカスタム", "ロングブラスターネクロ", "プライムシューターベッチュー", "リッター4K"]} mdxType="Profile" />
    <Profile name="Pino" nickname="???" icon="/images/isaribikai/pino.jpg" weapons={["ハイドラントカスタム", "わかばシューター", "N-ZAP89", "ヒーローローラー レプリカ", "ヒッセン"]} mdxType="Profile" />
    <Profile name="あゆみ！" nickname="???" icon="/images/isaribikai/ayumi.jpg" weapons={["オーバーフロッシャー", "オーバーフロッシャー デコ"]} mdxType="Profile" />
    <Profile name="マシッシー" nickname="???" icon="/images/isaribikai/mercy.jpg" weapons={["ホクサイベッチュー", "ホクサイ・ヒュー", "ヒーローブラシ レプリカ", "ヒーローローラー レプリカ", "スプラシューターコラボ", "スプラシューターベッチュー"]} mdxType="Profile" />
    <Profile name="ポコ©" nickname="???" icon="/images/isaribikai/poko.jpg" weapons={["ハイドラントカスタム"]} mdxType="Profile" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      